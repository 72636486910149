const hardStopText = "NOTE: Relivion MG may not be an appropriate therapy for your migraine. Feel free to contact <a href='https://www.relivion.com/contact/' target='_blank'>Relivion Customer Care</a> for additional questions about Relivion MG or speak with your physician about alternate treatments."

export const questionnaire = [
  {
    question: "Have you been <b>diagnosed with migraine headaches</b>?",
    answers: [
      {
        answer: "Yes",
        showConditional: false,
      },
      {
        answer: "No",
        showConditional: false,
        error: {
          header:
            "Relivion MG is indicated for the treatment of migraine and not other neurologic disorders.",
          body: hardStopText,
        },
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question: "What is <b>your age</b>?",
    answers: [
      {
        answer: "Under 18",
        showConditional: false,
        error: {
          header:
            "Relivion is not indicated for use in patients under the age of 18.",
          body: hardStopText,
        },
      },
      {
        answer: "18-70",
        showConditional: false,
      },
      {
        answer: "70+",
        showConditional: false,
        hasWarning: true,
        warning:
          "Note: The Relivion system is a platform that requires the user to interact with an app on a smart phone as well as well as a neurostimulation device that is worn on the head. The user should be comfortable with technology & head stimulation to have the best experience.",
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question: "How many <b>headache days</b> do you have <b>per month</b>?",
    answers: [
      {
        answer: "1-7",
        showConditional: false,
        hasWarning: true,
        warning:
          "NOTE: The effects of Neuromodulation Therapy may not be felt immediately, and the lower number of migraine days may increase the time needed for you to be satisfied with the Relivion Therapy.",
      },
      {
        answer: "8-15",
        showConditional: false,
      },
      {
        answer: "16-24",
        showConditional: false,
      },
      {
        answer: "25+",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Have you been diagnosed or are you suspected to have <b>heart disease</b> or <b>epilepsy</b>?",
    answers: [
      {
        answer: "Yes",
        error: {
          header:
            "Relivion® MG has not been studied in people with heart disease or epilepsy.",
          body: hardStopText,
        },
        showConditional: false,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question: "Please select your <b>sex assigned at birth</b>.",
    answers: [
      {
        answer: "Female",
        showConditional: true,
      },
      {
        answer: "Male",
        showConditional: false,
      },
    ],
    type: "radio",
    standardDemo: "sex",
    isConditional: false,
  },
  {
    question: "Are you <b>pregnant</b>?",
    answers: [
      {
        answer: "Yes",
        showConditional: false,
        error: {
          header:
            "The safety of electrical stimulation during pregnancy has not been established.",
          body: hardStopText,
        },
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: true,
  },
  {
    question:
      "In the <b>past 3 months</b> have you had any <b>facial or brain trauma</b>?",
    answers: [
      {
        answer: "Yes",
        showConditional: false,
        error: {
          header:
            "Persons with any facial or brain trauma in the past 3 month should not use Relivion. If you are interested in trying Relivion in the future, please visit us later.",
          body: hardStopText,
        },
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question: "<b>Do you have any of the following?</b>",
    questionBulletPoints: [
      " Implanted neurostimulator ",
      " Cardiac pacemaker ",
      " Defibrillator ",
      " Implanted metallic or electronic device in your head (other than dental implants)? ",
      " Wearable defibrillator",
    ],
    answers: [
      {
        answer: "Yes",
        showConditional: false,
        error: {
          header:
            "Persons with implanted neurostimulator, cardiac pacemaker, defibrillator or any other implanted metallic or electronic device in the head or a wearable defibrillator should not use Relivion.",
          body: hardStopText,
        },
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Do you suffer from <b>Allodynia</b>, or do you have <b>scalp sensitivity</b> during your migraine attacks?",
    answers: [
      {
        answer: "Yes",
        showConditional: false,
        hasWarning: true,
        warning:
          "NOTE: The Relivion Device must be worn tightly around the head during the treatment so persons who cannot tolerate pressure on their head during a migraine may not be able to stand the Relivion Therapy",
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question: "Please list any <b>allergies</b>.",
    answers: ["allergies"],
    type: "freeText",
    standardDemo: "allergies",
    isConditional: false,
  },
  {
    question:
      "Please list any <b>medical conditions</b> and any <b>prior surgeries</b>.",
    answers: ["medicalConditions"],
    type: "freeText",
    standardDemo: "medicalConditions",
    isConditional: false,
  },
  {
    question:
      "Please list any <b>current medicines</b>, <b>vitamins</b> or <b>dietary supplements</b> you take regularly. Please include the dosage.",
    answers: ["selfReportedMeds"],
    type: "freeText",
    standardDemo: "selfReportedMeds",
    isConditional: false,
  },
  {
    question:
      "Is there <b>anything else you want your doctor to know</b> about your condition or health?",
    answers: ["freeText"],
    type: "freeText",
    isConditional: false,
  },
];
